import i18n from '@/i18n/i18n'
export const getPermissions = () => {
    return [
        { name: i18n.t('reporting.issued_bills.issued_bills'), value: 100 },
        { name: i18n.t('general.reversal_bills'), value: 101 },
        { name: i18n.t('general.view_all_tables'), value: 102 },
        { name: i18n.t('general.view_all_bills'), value: 103 },
        { name: i18n.t('general.view_history'), value: 104 },
        { name: i18n.t('general.can_add_discount'), value: 105 },
        { name: i18n.t('general.show_prices_in_issued_bills'), value: 106 },
        { name: i18n.t('general.can_edit_ordered_items'), value: 107 },
        { name: i18n.t('general.can_delete_bills'), value: 108 },
        { name: i18n.t('keys.permissions.can_use_hidden_table'), value: 109},
        { name: i18n.t('general.show_only_selected_payment_types'), value: 110 },
        { name: i18n.t('general.card_pay_no_bill'), value: 111 },
        { name: i18n.t('general.return_to_card'), value: 112 },
        { name: i18n.t('general.do_stock_taking_from_scanner'), value: 113 },
        { name: i18n.t('general.create_stock_taking_from_scanner'), value: 114 },
        { name: i18n.t('general.edit_stock_taking_from_scanner'), value: 115 },
        { name: i18n.t('general.edit_scanned_stock_taking_items_from_other_employees'), value: 116 },

        { name: i18n.t('general.printouts'), value: 200 },
        { name: i18n.t('keys.permissions.printouts_employees'), value: 201 },
        { name: i18n.t('keys.permissions.printouts_per_item'), value: 202 },
        { name: i18n.t('keys.permissions.printouts_reversal_bills'), value: 203 },
        { name: i18n.t('keys.permissions.printouts_bills_with_discount'), value: 204 },
        { name: i18n.t('keys.permissions.printouts_payment_types'), value: 205 },
        { name: i18n.t('keys.permissions.printouts_stock'), value: 206 },
        { name: i18n.t('keys.permissions.printouts_cash_register_turnover'), value: 207 },
        { name: i18n.t('keys.permissions.printouts_end_of_day_turnover'), value: 208 },
        { name: i18n.t('keys.permissions.show_only_selected_categories'), value: 209 },
        { name: i18n.t('keys.permissions.printouts_tips'), value: 210 },
        { name: i18n.t('keys.permissions.printout_tips_everyone'), value: 211 },
        { name: i18n.t('keys.permissions.printout_tips_withdrawal'), value: 212 }


    ]
}